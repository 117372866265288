<template>
  <div>
    <!-- header -->
    <div class="flex flex-wrap items-center -ml-16 sm:-ml-24 -mt-8">
      <div class="mr-auto ml-16 sm:ml-24 mt-8">
        <p class="text-lg font-medium leading-big">Send Bulk Email Notifications</p>
        <p class="mt-4 text-grey-400">Use this service to notify customers of changes to events and products</p>
      </div>
    </div>

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- subject -->
    <table-input-row v-model="subject" label="Subject" placeholder="Subject" />

    <hr class="hidden sm:block my-20 -mx-24 border-none h-1 bg-hr">

    <!-- preview email address -->
    <table-input-row v-model="previewEmail" label="Preview Email Address" placeholder="Preview Email Address" type="email" />

    <hr class="hidden sm:block my-20 -mx-24 border-none h-1 bg-hr">

    <!-- message -->
    <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16">
      <p class="font-medium text-grey-400 max-w-sm w-full">Message</p>
      <div class="flex items-center mt-4 sm:mt-0 sm:col-span-2">
        <el-textarea v-model="message" textarea-class="rounded shadow-sm max-w-lg" class="w-full" rows="3" description="Only plain text supported for now" />
      </div>
    </div>

    <hr class="hidden sm:block my-20 -mx-24 border-none h-1 bg-hr">

    <!-- recipients -->
    <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16">
      <p class="font-medium text-grey-400 max-w-sm w-full">Recipients (BCC)</p>
      <div class="flex items-center mt-4 sm:mt-0 sm:col-span-2">
        <el-textarea
          v-model="recipientEmails"
          textarea-class="rounded shadow-sm max-w-lg"
          rows="3"
          class="w-full"
          @input="validate"
        >
          <template #description>
            <p class="mt-4 text-sm text-grey-400">
              Write or copy/paste lines of recipients, format is "email,name"
              <!-- <span v-if="emailsQuantity > 0" class="text-green-500">{{ emailsQuantity }} emails added</span> -->
            </p>
            <p v-if="recipientEmails && !errorMessagesList.length" class="text-green-500">{{ emailsQuantity }} emails added.</p>
            <p
              v-for="(error, i) in errorMessagesList"
              :key="i"
              class="mt-4 text-sm text-red-100"
            >
              Line {{ error.index + 1 }} "{{ error.email }}" not a valid email address.
            </p>
          </template>
        </el-textarea>
      </div>
    </div>

    <hr class="hidden sm:block my-20 -mx-24 border-none h-1 bg-hr">

    <el-button
      type="primary"
      :loading="loading !== '' && loading === 'preview'"
      class="min-w-160"
      @click="onSendPreview"
    >
      Send Preview
    </el-button>

    <el-checkbox v-model="isSendToAll" class="mt-40">Activate Send To All</el-checkbox>

    <el-button
      type="primary"
      :loading="loading !== '' && loading === 'all'"
      :disabled="!recipientEmails || !isSendToAll || errorMessagesList.length > 0"
      class="min-w-160 mt-20"
      @click="onSendToAll"
    >
      Send To All
    </el-button>

    <!-- <el-message type="warning" class="mt-20 text-yellow-700 text-sm cursor-pointer whitespace-no-wrap">
      <div>
        Something went wrong! <span class="underline font-medium hover:text-yellow-600 ml-4 whitespace-normal">Please try again</span>
      </div>
    </el-message> -->
  </div>
</template>

<script>
// import ElMessage from '@/components/shared/ELMessage'
const ElButton = () => import('@/components/shared/ELButton')
const TableInputRow = () => import('@/components/product/TableInputRow')
const ElTextarea = () => import('@/components/shared/ELTextarea')
const ElCheckbox = () => import('@/components/shared/ELCheckbox')

export default {
  name: 'EmailEditorButton',

  components: {
    ElButton,
    TableInputRow,
    ElTextarea,
    ElCheckbox
    // ElMessage
  },

  props: {
    loading: { type: String, default: '' }
  },

  data () {
    return {
      subject: '',
      previewEmail: '',
      message: '',
      recipientEmails: '',
      emailsArray: [],
      isSendToAll: false,
      errorMessages: []
    }
  },

  computed: {
    transformedStringToArray () {
      const splitedByNewLine = this.recipientEmails.split('\n')
      const splitedByComma = splitedByNewLine.filter(el => el).map(el => {
        return el.split(',')
      })
      return splitedByComma
    },

    emailsQuantity () {
      if (!this.recipientEmails) return ''
      const splitedByComma = this.transformedStringToArray
      return `${splitedByComma.length}`
    },

    errorMessagesList () {
      return this.errorMessages.filter(item => item)
    }
  },

  methods: {
    validate (value) {
      this.errorMessages = []

      const splitedByNewLine = value.split('\n')

      splitedByNewLine.filter(el => el).forEach((el, i) => {
        // eslint-disable-next-line no-useless-escape
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const email = el.split(',')[0]

        if (!re.test(email)) {
          this.errorMessages[i] = { email, index: i }
        } else {
          this.errorMessages.splice(i, 1)
        }
      })
    },

    onSendPreview () {
      this.$emit('on-send', {
        eventName: 'preview',
        data: {
          subject: this.subject,
          body: this.message,
          recipient_list: [{ email: this.previewEmail, full_name: '' }]
        }
      })
    },

    onSendToAll () {
      const arrayOfEmails = this.transformedStringToArray.map(email => {
        return { email: email[0], full_name: email[1] ? email[1] : '' }
      })

      this.$emit('on-send', {
        eventName: 'all',
        data: {
          subject: this.subject,
          body: this.message,
          recipient_list: arrayOfEmails
        }
      })
    },

    resetFields () {
      this.subject = ''
      this.previewEmail = ''
      this.recipientEmails = ''
      this.message = ''
    }
  }
}
</script>
